'use client'

import { useTranslations } from 'next-intl'

const NotFound = () => {
  const t = useTranslations()
  return (
    <>
      {/* Next app router still not support metadata */}
      <title>Error | Sorry, something went wrong.</title>

      <div className="relative min-h-[calc(100%_-_var(--spacing-fixed-top))] w-full">
        <div className="text-label-l1 min768:top-[96px] min768:translate-y-0 absolute left-0 right-0 top-[calc(15%_+_var(--spacing-fixed-top)_/_2)] z-[1] mx-4 -translate-y-full transform">
          <h2 className="mb-[4px] text-center text-lg font-semibold">
            {t('something_went_wrong')}
          </h2>
          <p className="text-md text-label-l2 text-center">
            {t('something_went_wrong_description')}
          </p>
        </div>
      </div>
    </>
  )
}

export default NotFound
